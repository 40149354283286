import { ethers } from "ethers";
import { tokenABI } from "@/app/abi";

const tokenContractAddress = process.env.NEXT_PUBLIC_TOKEN_ADDRESS!;

const provider = new ethers.JsonRpcProvider(
  process.env.NEXT_PUBLIC_PROVIDER_URL,
);

export async function getTokenAllowance(owner: string, spender: string) {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.allowance(owner, spender);
}

const MAX_RETRIES = 3;

export async function fetchWithRetry(fetchFn: any, ...args: any[]) {
  let attempts = 0;
  while (attempts < MAX_RETRIES) {
    try {
      return await fetchFn(...args);
    } catch (error) {
      attempts++;
      if (attempts >= MAX_RETRIES) {
        throw error;
      }
      console.warn(`Retrying ${fetchFn.name}... attempt ${attempts}`);
    }
  }
}

export async function getTokenBalanceOf(account: string) {
  try {
    const contract = new ethers.Contract(
      tokenContractAddress,
      tokenABI,
      provider,
    );

    const balance = await fetchWithRetry(contract.balanceOf, account);

    const decimals = await fetchWithRetry(contract.decimals);

    // Note: We do not fetch symbol here, as it should be cached and fetched only once.
    return { balance, decimals };
  } catch (error) {
    console.error(
      `Error fetching token balance for account ${account}:`,
      error,
    );

    if ((error as any).code === "CALL_EXCEPTION") {
      console.error(
        `The call to the contract at ${tokenContractAddress} failed. This may be due to an incorrect contract address or missing method implementation in the contract. Please verify the contract address and ABI.`,
      );
    }
    throw error;
  }
}

export async function getTokenClock() {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.clock();
}

export async function getTokenDecimals() {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.decimals();
}

export async function getTokenName() {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.name();
}

export async function getTokenSymbol() {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.symbol();
}

export async function getTokenTotalSupply() {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.totalSupply();
}

export async function getTokenNonces(owner: string) {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.nonces(owner);
}

export async function getTokenDomainSeparator() {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.DOMAIN_SEPARATOR();
}

export async function getTokenPastTotalSupply(timepoint: number) {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.getPastTotalSupply(timepoint);
}

export async function getTokenPastVotes(account: string, timepoint: number) {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.getPastVotes(account, timepoint);
}

export async function getTokenCheckpoints(account: string, pos: number) {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.checkpoints(account, pos);
}

export async function getTokenNumCheckpoints(account: string) {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.numCheckpoints(account);
}

export async function getTokenDelegates(account: string) {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  return await contract.delegates(account);
}

export async function getDelegations(account: string) {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );
  const filter = contract.filters.DelegateChanged(null, null, account);
  const events = await contract.queryFilter(filter);
  return events.length;
}

export async function getVotingPower(account: string): Promise<string> {
  const contract = new ethers.Contract(
    tokenContractAddress,
    tokenABI,
    provider,
  );

  const data = await contract.getVotes(account);
  const decimals = 18;
  const rawValue = parseFloat(ethers.formatUnits(data, decimals));

  const formatVotingPower = (value: number): string => {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

    return formatter.format(value);
  };

  return formatVotingPower(rawValue);
}
