"use client";

import React, { useState } from "react";
import Link from "next/link";
import { ClipboardDocumentIcon, CheckIcon } from "@heroicons/react/24/outline";
import {
  truncateAddressNav,
  truncateAddressXs,
  truncateAddressSm,
  truncateAddressMd,
  truncateAddressXl,
  truncateAddress64,
} from "@/app/helpers/truncateAddress";

interface ClipboardCopyProps {
  truncate?:
    | "card"
    | "nav"
    | "xxs"
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "64"
    | undefined;
  address: string | undefined;
  showAddress?: boolean;
}

const ClipboardCopy: React.FC<ClipboardCopyProps> = ({
  address,
  truncate,
  showAddress = true,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const getTruncatedAddress = (
    address: string,
    truncate: ClipboardCopyProps["truncate"],
  ) => {
    switch (truncate) {
      case "card":
        return truncateAddressNav(address);
      case "nav":
        return truncateAddressNav(address);
      case "xs":
        return truncateAddressXs(address);
      case "sm":
        return truncateAddressSm(address);
      case "md":
        return truncateAddressMd(address);
      case "xl":
        return truncateAddressXl(address);
      case "64":
        return truncateAddress64(address);
      default:
        return address;
    }
  };

  const handleCopy = async () => {
    if (address) {
      try {
        await navigator.clipboard.writeText(address);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 3000);
      } catch (err) {
        setCopySuccess(false);
      }
    }
  };

  return (
    <div className="flex relative items-center justify-center cursor-pointer max-w-max min-w-[30px]">
      {showAddress && (
        <Link
          href={`/karratco/view/profile/${address}`}
          title={address}
          className="truncate max-w-full"
        >
          {getTruncatedAddress(address!, truncate)}
        </Link>
      )}
      <button
        type="button"
        className="inline-block w-4 h-4"
        onClick={handleCopy}
        style={{
          background: "none",
          border: "none",
          padding: 0,
          cursor: "pointer",
        }}
      >
        {copySuccess ? (
          <CheckIcon className="w-4 h-4 ml-1 text-accent" />
        ) : (
          <ClipboardDocumentIcon className="w-4 h-4 ml-1 cursor-pointer text-yellow" />
        )}
      </button>
    </div>
  );
};

export default ClipboardCopy;
