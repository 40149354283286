import { atom } from "jotai";
import { UsersAccountReturnType } from "@/app/types/account";

export const userSettingsAtom = atom<UsersAccountReturnType>({
  wallet_address: "",
  profile_name: "",
  avatar_url: "",
  bio: "",
  twitter_handle: "",
  twitter_link: "",
  delegation_statement: "",
  is_seeking_delegation: true,
});

export const userInfoAtom = atom<UsersAccountReturnType[]>([
  {
    wallet_address: "",
    profile_name: "",
    avatar_url: "",
    bio: "",
    twitter_handle: "",
    twitter_link: "",
    delegation_statement: "",
    is_seeking_delegation: true,
  },
]);

export const postMadeAtom = atom(0);
