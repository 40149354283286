import { atom } from "jotai";

export const proposalThresholdAtom = atom<string | null>(null);
export const quorumNumeratorAtom = atom<string | null>(null);
export const quorumDenominatorAtom = atom<string | null>(null);
export const votingPeriodAtom = atom<string | null>(null);
export const votingDelayAtom = atom<string | null>(null);
export const circulatingSupplyAtom = atom<string>("0");
export const tokenAddressAtom = atom<string | null>(null);
export const timelockAddressAtom = atom<string | null>(null);
export const quorumNeededAtom = atom<string | null>(null);
export const currentBlockNumberAtom = atom<number | null>(6011180);
export const eventTriggeredAtom = atom(false);
